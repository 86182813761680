// envConfig.js

const eemsURL = {
  stage: 'https://ees-stage1.optum.com/eems/',
  adoption: 'https://eems-adoption.optum.com/eems/',
  production: 'https://employereservices.optum.com/eems/',
};

const serviceURL = {
  stage: 'https://eems-primeui-stglb.optum.com/primeservice/',
  adoption: 'https://eems-prime-adop-dmz.optum.com/primeservice/',
  production: 'https://eems-prime-prod-dmz.optum.com/primeservice/',
};

export const getEnvironment = () => {
  switch (true) {
    case window.location.hostname.includes('prod'):
      return 'production';
    case window.location.hostname.includes('stglb'):
      return 'stage';
    case window.location.hostname.includes('adop'):
      return 'adoption';
    case window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1':
      return 'stage';
    default:
      return 'adoption';
  }
};

export const getEEMSURL = () => {
  return eemsURL[getEnvironment()];
};

export const getServiceURL = () => {
  return serviceURL[getEnvironment()];
};
