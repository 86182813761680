import React, { useEffect, useState } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Link } from '@abyss/web/ui/Link';
import { Layout } from '@abyss/web/ui/Layout';
import { Badge } from '@abyss/web/ui/Badge';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { PolicyListTable } from '../PolicyListTable';
import { DEFAULT_REPORT_TYPE } from '@src/components/Reports/Reports';

import { getEEMSURL } from '@src/env.config';
import { getCachedUserDetails } from '@src/utils';
import { CustomHeader } from './CustomHeader';
import { LoadingScreen } from '@src/common/LoadingScreen';

export const FilesListTable = (prop) => {
  const { parentFilesList, form, onClear, isLoading } = prop;
  const { userType } = getCachedUserDetails();
  const [isInternal, setIsInternal] = useState(false);

  useEffect(() => {
    const { userType } = getCachedUserDetails();
    setIsInternal(userType === 'I');
  }, []);

  const router = useRouter();

  const columns = React.useMemo(
    () => [
      {
        Header: 'File ID',
        accessor: 'fileID',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'submiterGroupName',
        minWidth: 500,
        canToggleVisibilty: false,
        isHiddenByDefault: false,
        Cell: ({ value, row }) => {
          return isInternal ? (
            <Link
              href={`${getEEMSURL()}profile.do?asgnId=${row?.original?.asgnSbmtId
                ?.toString()
                ?.trim()}&version=${row?.original?.version}&srchDestSysCd=${
                row?.original?.elecSysCode
              }`}
            >
              {value}
            </Link>
          ) : (
            <strong>{value}</strong>
          );
        },
      },
      {
        Header: 'Received',
        accessor: 'fileRecvDate',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
      {
        Header: 'Status',
        accessor: 'fileStatus',
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          const statusLabel = value.charAt(0).toUpperCase() + value.slice(1);
          return <Badge variant={value}>{statusLabel}</Badge>;
        },
      },
      {
        Header: 'File Name',
        accessor: 'fileName',
        minWidth: 300,
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        Cell: ({ value }) => {
          return <strong>{value}</strong>;
        },
      },
    ],
    [isInternal]
  );

  const dataTableProps = useDataTable({
    renderSubComponent: (row) => {
      return <PolicyListTable fileID={row.values.fileID} form={form} />;
    },
    initialData: parentFilesList || [],
    initialColumns: columns,
    showSelection: false,
    showPagination: true,
    pageSizeOptions: [10, 25, 50, 100, 500],
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: true,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: userType === 'I' ? true : false,
    showColumnVisibilityConfig: true,
    individualActions: [
      {
        label: 'Rprt',
        onClick: async ({ row }) => {
          console.log(await router);
          await router.navigate(
            `/home/reports/${row.values.fileID}/${DEFAULT_REPORT_TYPE}`
          );
        },
      },
    ],

    customHeaderSection: (
      <>
        <CustomHeader onClear={onClear} isLoading={isLoading} />
        {isLoading ? <LoadingScreen /> : null}
      </>
    ),
  });

  useEffect(() => {
    if (parentFilesList) dataTableProps.setData(parentFilesList);
  }, [parentFilesList]);

  useEffect(() => {
    dataTableProps.setColumns(columns, true);
  }, [columns]);

  if (!parentFilesList) {
    return <LoadingScreen />;
  }
  return (
    <Layout.Stack
      css={
        !isInternal
          ? {
              '.abyss-data-table-custom-header-section': {
                minWidth: '84%',
              },
            }
          : {}
      }
    >
      <DataTable
        css={{
          'abyss-table-cell-expanded': {
            width: '100%',
          },
        }}
        hideTitleHeader
        tableState={dataTableProps}
      />
    </Layout.Stack>
  );
};
